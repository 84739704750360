import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 943.000000 943.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,943.000000) scale(0.100000,-0.100000)" >
				<path d="M5325 7282 c-10 -26 -5 -342 5 -342 6 0 10 -5 10 -11 0 -5 -4 -7 -10
-4 -5 3 -10 2 -10 -3 0 -6 75 -10 179 -11 163 -2 180 0 190 16 9 16 10 16 11
-4 0 -22 -1 -23 -182 -26 l-183 -2 186 -3 c133 -1 188 1 197 10 16 16 17 374
2 383 -6 4 -96 8 -200 8 -150 2 -191 -1 -195 -11z m390 -14 c3 -13 4 -81 3
-153 l-4 -130 -2 142 c-2 123 -4 143 -19 152 -17 10 -17 10 -1 11 10 0 20 -10
23 -22z m-378 -240 c-1 -46 -3 -9 -3 82 0 91 2 128 3 83 2 -46 2 -120 0 -165z
m100 228 c9 -8 1 -14 -22 -16 -42 -5 -65 0 -65 15 0 11 10 14 41 11 22 -2 43
-7 46 -10z m121 7 c-21 -2 -55 -2 -75 0 -21 2 -4 4 37 4 41 0 58 -2 38 -4z
m100 -15 c-2 -16 -9 -22 -20 -20 -10 2 -18 -3 -18 -10 0 -10 -18 -12 -75 -10
-56 3 -84 0 -111 -13 l-36 -17 4 -73 c2 -41 -1 -70 -5 -66 -5 5 -7 41 -5 80 2
53 0 70 -9 67 -7 -3 -15 -1 -18 3 -6 10 50 20 154 27 62 5 74 9 78 25 3 12 11
18 19 15 7 -3 16 -1 19 4 11 18 26 10 23 -12z m-38 -139 c0 -39 -4 -69 -10
-69 -6 0 -10 32 -10 76 0 47 4 73 10 69 6 -3 10 -37 10 -76z m72 6 c-2 -41 -8
-75 -13 -75 -14 0 -11 137 4 143 6 3 12 6 12 6 0 1 -1 -33 -3 -74z m-172 -95
c38 0 71 -3 73 -7 6 -10 -161 -8 -170 1 -4 4 -1 11 7 16 9 6 16 6 18 0 2 -6
35 -10 72 -10z m176 -36 c0 -22 -2 -30 -7 -22 -8 12 -13 4 -10 -14 2 -13 -27
-9 -35 4 -4 6 -3 8 4 4 6 -3 13 3 17 14 3 11 11 20 16 20 5 0 8 3 8 8 -2 9 0
22 4 22 1 0 2 -16 3 -36z m-293 -11 c6 -30 -4 -36 -31 -17 -29 20 -28 40 2 40
18 0 26 -7 29 -23z m208 10 c0 -5 2 -16 4 -25 2 -10 -1 -18 -6 -18 -5 0 -9 5
-9 10 0 6 0 13 0 18 0 4 -35 10 -77 13 l-78 5 83 2 c45 1 82 -1 83 -5z m106
-55 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-300 -4 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m260 0 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M4890 7020 c-14 -25 -12 -260 2 -277 14 -18 279 -18 296 -1 18 18 16
257 -2 281 -11 15 -31 17 -149 17 -126 0 -137 -1 -147 -20z"/>
<path d="M3718 6843 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3587 6822 c-38 -9 -67 -19 -64 -22 3 -3 -9 -5 -27 -6 -18 0 -48 -8
-67 -16 -19 -9 -62 -28 -96 -42 -265 -111 -512 -316 -675 -561 -156 -236 -257
-552 -258 -808 l-1 -72 -147 -151 c-231 -237 -386 -407 -537 -589 -28 -33 -56
-66 -62 -73 -7 -7 -13 -16 -13 -19 0 -2 46 44 103 103 310 327 723 705 1077
985 298 236 355 279 369 279 6 0 11 3 11 8 0 4 20 20 45 35 25 16 42 34 38 39
-3 6 -2 9 3 5 5 -3 33 12 62 33 40 28 49 39 37 44 -9 3 -13 2 -10 -4 3 -6 -1
-10 -9 -10 -8 0 -18 5 -21 10 -3 6 -19 10 -35 10 -16 0 -31 3 -33 8 -7 11 57
9 65 -3 13 -18 17 0 9 38 -5 21 -5 37 0 37 5 0 9 -8 10 -17 0 -14 2 -15 6 -3
4 8 16 22 27 32 l21 17 -25 -3 c-33 -3 -62 -19 -129 -73 -31 -24 -60 -47 -66
-51 -5 -4 -24 -18 -41 -32 -17 -14 -46 -36 -64 -50 -19 -14 -50 -39 -70 -56
-19 -17 -62 -53 -96 -80 -63 -50 -164 -137 -218 -187 -66 -61 -117 -100 -123
-93 -10 10 32 210 66 311 26 80 90 225 124 280 29 48 92 141 101 151 6 6 36
42 66 79 52 64 175 174 250 225 19 13 37 27 40 30 8 10 181 106 232 129 53 24
225 81 248 82 8 0 0 5 -18 10 -19 6 -39 8 -45 5 -7 -2 -1 -5 13 -6 21 -1 19
-3 -14 -15 -21 -7 -44 -11 -51 -9 -7 3 -16 1 -20 -5 -3 -6 -14 -11 -23 -11
-13 0 -11 5 8 20 14 10 34 18 45 16 11 -2 14 -1 8 1 -7 3 -13 9 -13 14 0 5 -8
9 -17 10 -10 1 -2 5 17 10 19 5 53 7 75 6 22 -2 31 -2 21 0 -16 4 -17 7 -5 14
22 14 -26 10 -104 -9z m-262 -781 c-3 -6 -11 -11 -17 -11 -6 0 -6 6 2 15 14
17 26 13 15 -4z m-21 -65 c-10 -8 -21 -12 -24 -10 -5 6 21 24 34 24 6 0 1 -6
-10 -14z m22 -21 c-11 -8 -24 -15 -30 -15 -5 0 -1 7 10 15 10 8 24 14 29 14 6
0 1 -6 -9 -14z"/>
<path d="M5306 6644 c-3 -9 -6 -51 -6 -95 0 -94 0 -94 105 -94 105 0 105 0
105 94 0 115 4 111 -105 111 -75 0 -94 -3 -99 -16z"/>
<path d="M3556 6244 c-11 -8 -16 -14 -10 -14 13 0 39 18 34 24 -3 2 -14 -2
-24 -10z"/>
<path d="M3447 6169 c-32 -23 -56 -43 -53 -47 3 -3 16 4 29 15 12 11 37 28 55
39 17 10 32 22 32 26 0 11 2 12 -63 -33z"/>
<path d="M5626 6092 c-79 -2 -112 -7 -123 -17 -13 -13 -12 -15 1 -15 13 0 15
-6 10 -26 -6 -21 -4 -25 7 -19 8 5 11 5 7 1 -3 -4 0 -29 7 -54 8 -27 10 -49 5
-52 -11 -7 -16 -412 -5 -430 4 -6 7 -29 7 -51 0 -21 4 -42 10 -45 7 -5 9 0 5
15 -4 18 -3 20 9 10 8 -6 14 -23 14 -38 0 -21 -2 -23 -11 -11 -9 12 -10 12 -7
1 6 -20 23 -24 39 -9 16 15 49 20 49 8 0 -4 -13 -13 -30 -19 -16 -5 -30 -16
-30 -23 0 -9 3 -9 10 2 5 8 10 12 11 8 1 -5 2 -12 3 -16 0 -5 10 -17 20 -28
19 -19 20 -19 13 5 -6 26 7 51 27 51 16 0 36 -69 23 -77 -19 -11 65 -34 148
-40 62 -4 88 -2 115 11 34 16 34 16 5 11 -16 -3 -48 -7 -70 -10 -38 -4 -30 -1
23 11 13 2 20 9 17 14 -3 5 6 12 20 16 14 3 25 10 25 15 0 5 5 9 10 9 18 0 10
-17 -12 -28 -19 -9 -20 -11 -5 -11 9 -1 27 4 40 11 12 6 29 14 37 18 8 4 18
22 23 41 8 29 10 31 19 15 7 -12 7 -21 -1 -29 -23 -23 -10 -29 14 -7 32 30 57
78 44 86 -14 9 -10 27 5 21 10 -4 15 7 20 41 7 60 9 542 2 550 -10 10 -6 33 4
27 6 -3 15 6 20 20 14 38 12 43 -6 16 -13 -18 -18 -20 -25 -10 -6 10 -9 8 -9
-8 0 -28 -16 -30 -23 -3 -3 10 -2 19 2 18 3 -1 17 4 31 10 21 9 12 11 -53 10
-66 -2 -77 -4 -71 -17 22 -54 27 -96 31 -271 3 -107 10 -201 15 -207 7 -9 6
-13 -3 -13 -9 0 -10 -3 -4 -8 6 -4 10 -35 11 -69 0 -58 -14 -69 -17 -13 0 14
-7 -6 -14 -43 -8 -38 -20 -76 -27 -85 -11 -13 -11 -15 3 -10 14 6 16 2 10 -20
-7 -27 -51 -46 -51 -21 0 8 -3 9 -14 0 -7 -6 -31 -17 -52 -25 -21 -8 -33 -15
-26 -15 28 -2 9 -21 -20 -20 -25 0 -28 2 -14 8 15 6 9 10 -25 21 -24 7 -54 19
-66 27 -15 9 -23 10 -23 3 0 -16 -42 -8 -48 8 -3 11 0 12 17 3 21 -12 28 -3
12 15 -5 5 -14 27 -22 49 -11 36 -13 38 -18 16 -6 -21 -5 104 2 488 2 129 1
139 -10 100 l-13 -44 6 49 c3 27 8 55 11 62 3 8 0 14 -6 14 -6 0 -11 -12 -12
-27 l-1 -28 -7 25 c-8 27 2 40 31 40 12 0 16 -5 11 -17 -3 -12 1 -10 12 5 9
13 15 24 14 25 -2 1 -53 1 -113 -1z m-7 -29 c-7 -10 -15 -20 -16 -22 -2 -2 -3
5 -3 17 0 14 6 22 16 22 14 0 15 -3 3 -17z m481 -19 c-10 -45 -16 -51 -25 -25
-6 21 9 61 24 61 5 0 5 -16 1 -36z m-548 11 c0 -5 -5 -11 -11 -13 -6 -2 -11 4
-11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m54 -66 c-10 -39 -26 -35 -26 7 0
24 5 31 23 31 11 0 11 -8 3 -38z m561 -36 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18
2 -7 2 -21 0 -30z m-527 -23 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10
11 10 2 0 4 -4 4 -10z m480 0 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10
11 10 2 0 4 -4 4 -10z m-439 -30 c5 0 8 -81 7 -192 -1 -106 -3 -173 -5 -150
-3 32 -8 42 -18 38 -12 -4 -15 23 -17 155 -2 129 -1 158 10 154 8 -3 18 -5 23
-5z m-114 -227 c-1 -43 -3 -8 -3 77 0 85 2 120 3 78 2 -43 2 -113 0 -155z m33
77 c0 -93 -4 -150 -10 -150 -6 0 -10 57 -10 150 0 93 4 150 10 150 6 0 10 -57
10 -150z m497 -112 c-2 -24 -4 -7 -4 37 0 44 2 63 4 43 2 -21 2 -57 0 -80z
m38 -62 c0 -13 -3 -27 -7 -31 -5 -6 -5 40 1 53 4 8 7 -1 6 -22z m-559 -15 c1
-12 -3 -24 -9 -28 -6 -3 -7 -1 -3 5 3 6 2 13 -4 17 -14 9 -12 37 3 33 6 -3 13
-15 13 -27z m34 -62 c-13 -29 -14 -30 -27 -13 -18 25 -16 34 7 34 11 0 20 5
20 12 0 6 3 8 6 5 4 -4 1 -21 -6 -38z m557 -21 c-3 -7 -5 -2 -5 12 0 14 2 19
5 13 2 -7 2 -19 0 -25z m-516 15 c0 -5 2 -18 3 -30 1 -12 -1 -28 -5 -35 -10
-16 -71 -20 -66 -5 2 7 12 12 23 12 14 0 20 8 22 33 2 17 7 32 13 32 5 0 9 -3
10 -7z m469 -67 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
10 -2 10 -4z m-30 -31 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15
6 0 10 -7 10 -15z m33 -35 c-8 -29 -21 -25 -15 5 2 14 8 23 12 20 5 -3 6 -14
3 -25z m-296 -87 c13 -6 6 -10 -27 -14 l-45 -6 43 -1 c23 -1 42 -6 42 -11 0
-8 -24 -6 -80 5 l-25 6 25 13 c29 17 41 18 67 8z"/>
<path d="M4907 6079 c-9 -5 -22 -7 -27 -4 -6 3 -10 -1 -10 -9 0 -18 -30 -36
-57 -35 -15 0 -15 2 3 9 12 5 25 14 29 21 5 6 -15 -1 -43 -16 -32 -17 -55 -38
-62 -56 -7 -16 -16 -26 -21 -23 -13 8 -29 -41 -29 -90 0 -46 18 -109 28 -102
10 6 32 -21 25 -28 -8 -8 21 -31 70 -56 22 -11 37 -14 34 -7 -2 6 -14 14 -28
18 -20 6 -21 7 -4 8 26 1 71 -29 57 -38 -6 -4 19 -15 56 -24 37 -10 82 -23
100 -28 17 -6 42 -7 55 -4 16 4 18 4 8 -3 -11 -8 -10 -12 8 -21 12 -7 26 -9
31 -6 13 8 58 -27 53 -42 -2 -6 1 -15 8 -19 8 -5 10 -3 4 7 -7 12 -6 12 7 1
13 -10 17 -10 20 0 8 23 38 -11 38 -43 0 -15 5 -31 10 -34 12 -7 4 -25 -11
-25 -5 0 -9 11 -9 24 0 35 -10 56 -27 56 -10 0 -13 -5 -8 -17 22 -54 23 -74 7
-105 -9 -18 -22 -39 -29 -46 -17 -17 -17 -29 1 -35 16 -6 60 39 71 72 3 11 13
21 21 21 18 0 19 50 2 70 -11 12 -10 12 5 1 9 -7 17 -10 17 -6 0 4 7 2 15 -5
21 -18 19 -48 -5 -55 -11 -3 -20 -15 -20 -26 0 -10 -9 -28 -20 -39 -20 -20
-18 -45 4 -53 17 -7 56 13 70 35 14 24 1 34 -19 14 -20 -19 -32 -21 -16 -3 6
8 9 16 6 19 -3 3 4 13 15 23 11 10 20 30 21 44 l1 26 7 -25 c5 -17 9 -21 15
-12 4 6 5 16 1 21 -3 5 -1 12 5 16 5 3 10 -3 10 -14 0 -31 -11 -50 -25 -45 -7
3 -16 1 -20 -5 -3 -6 0 -14 9 -17 8 -3 12 -12 9 -20 -10 -27 7 -14 23 18 11
21 17 54 16 94 -1 58 -17 122 -28 112 -2 -3 0 -17 6 -32 15 -39 12 -55 -5 -26
l-14 25 -1 -23 c0 -30 -24 -24 -28 7 -2 12 -14 33 -28 48 -13 14 -24 32 -24
40 0 8 -4 11 -10 8 -6 -4 -7 1 -3 11 4 11 8 13 11 6 6 -18 22 -15 22 4 0 14 3
14 15 4 8 -7 15 -9 15 -6 0 4 -7 14 -15 22 -8 9 -15 11 -15 5 0 -6 -5 -11 -11
-11 -6 0 -8 7 -5 16 5 12 1 14 -15 9 -13 -4 -19 -3 -16 2 4 6 -6 16 -21 24
-15 7 -31 15 -36 18 -5 2 -6 -1 -2 -7 5 -8 2 -10 -8 -6 -9 3 -13 11 -9 17 4 7
-6 8 -31 4 -32 -5 -37 -3 -32 10 5 12 1 14 -14 10 -11 -3 -20 -1 -20 3 0 5
-23 13 -50 19 -57 12 -119 41 -146 68 -13 13 -16 14 -12 3 7 -22 77 -66 123
-77 l40 -11 -52 2 c-29 0 -53 5 -53 9 0 4 -9 3 -20 -3 -14 -7 -20 -7 -20 0 0
5 7 10 15 10 23 0 18 16 -10 28 -33 15 -35 15 -35 -2 0 -21 -17 -29 -45 -22
-23 6 -24 7 -8 26 10 11 23 20 30 20 7 0 10 7 7 15 -4 8 -1 15 5 15 7 0 9 15
5 43 -9 61 25 123 80 148 23 10 33 19 24 19 -10 0 -18 5 -18 11 0 5 3 8 8 6 4
-3 45 -1 92 3 l85 7 -105 1 c-58 1 -113 -3 -123 -9z m15 -36 c-7 -3 -18 -11
-26 -18 -7 -8 -16 -12 -19 -9 -9 10 4 34 19 34 8 0 14 5 14 12 0 9 3 8 13 -1
10 -11 9 -14 -1 -18z m-60 -36 c15 -12 15 -14 -4 -26 -18 -11 -20 -11 -15 3 5
12 0 16 -21 16 -21 0 -24 2 -12 10 20 13 32 12 52 -3z m-38 -54 c-5 -21 -8
-57 -7 -80 0 -42 -10 -57 -20 -29 -8 21 14 146 26 146 6 0 6 -14 1 -37z m-30
6 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z
m-19 -28 c3 -5 -4 -13 -16 -16 -23 -8 -34 -1 -24 14 8 13 32 14 40 2z m66 -24
c-8 -8 -11 -7 -11 4 0 20 13 34 18 19 3 -7 -1 -17 -7 -23z m-126 -42 c0 -64
-13 -62 -14 2 -1 26 7 63 13 63 0 0 1 -29 1 -65z m73 -117 c-2 -8 8 -11 29 -9
21 1 39 -4 50 -15 16 -16 16 -16 -4 -10 -11 4 -35 8 -52 11 -42 5 -57 15 -40
26 18 11 19 11 17 -3z m216 -12 c21 -7 35 -16 30 -19 -15 -9 -62 4 -68 19 -3
8 -4 14 -3 14 1 0 19 -6 41 -14z m113 -38 l11 -23 10 25 c12 28 12 28 17 -17
5 -44 -14 -47 -63 -10 -49 38 -52 47 -14 47 20 0 32 -7 39 -22z m127 -7 c3 -5
-1 -11 -9 -15 -9 -3 -15 0 -15 9 0 16 16 20 24 6z m-48 -38 c-11 -11 -17 4 -9
23 7 18 8 18 11 1 2 -10 1 -21 -2 -24z m-246 7 c13 -9 13 -10 0 -10 -8 0 -22
5 -30 10 -13 9 -13 10 0 10 8 0 22 -5 30 -10z m148 -5 c13 -12 -7 -12 -58 1
l-55 14 54 -5 c29 -2 56 -7 59 -10z m-43 -33 c-6 -7 -34 11 -29 19 3 5 11 3
19 -4 7 -6 12 -13 10 -15z m183 -19 c-10 -2 -18 -13 -18 -23 0 -12 -5 -17 -14
-14 -25 10 -7 44 23 43 17 -1 20 -3 9 -6z m45 -9 c8 -8 -3 -34 -14 -34 -5 0
-9 9 -9 20 0 19 11 26 23 14z m13 -101 c-11 -11 -19 6 -11 24 8 17 8 17 12 0
3 -10 2 -21 -1 -24z m4 -172 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16
10 16 6 0 10 -4 10 -9z"/>
<path d="M6310 6080 c0 -5 5 -10 11 -10 6 0 8 -8 4 -19 -3 -11 -1 -22 5 -26 5
-3 10 -23 10 -43 0 -26 3 -33 9 -22 6 8 8 28 5 45 -4 28 -3 27 7 -5 8 -25 8
-40 0 -52 -7 -12 -10 -102 -9 -270 l2 -253 6 245 6 245 1 -275 c0 -151 -2
-264 -6 -250 -7 23 -8 24 -14 5 -3 -11 -8 -43 -9 -71 -2 -29 -6 -50 -10 -47
-5 2 -8 -1 -8 -6 0 -7 56 -12 163 -13 l162 -2 -170 -6 -170 -6 170 -4 170 -4
-175 -6 -175 -6 200 -2 c138 -1 195 1 185 8 -11 8 3 9 45 5 43 -5 71 -2 100 9
l40 16 -35 -5 c-25 -4 -31 -2 -20 5 12 8 13 12 2 19 -10 6 -7 10 13 15 16 4
32 3 38 -3 6 -6 15 -5 24 5 25 25 37 15 13 -11 -27 -29 -15 -33 19 -7 67 51
80 64 74 77 -3 9 1 15 10 15 16 0 27 40 27 101 0 38 -1 39 -35 39 -35 0 -35 1
-35 43 0 23 5 49 10 57 8 13 10 12 10 -2 0 -10 5 -18 11 -18 6 0 9 7 5 15 -3
8 -1 15 4 15 6 0 10 -7 10 -15 0 -8 4 -15 9 -15 5 0 7 9 4 20 -7 26 -59 67
-93 73 l-25 4 26 2 c14 0 30 -3 37 -9 7 -6 7 -5 0 3 -21 23 -88 59 -88 49 0
-6 6 -13 13 -15 9 -4 8 -8 -3 -16 -13 -9 -12 -12 5 -26 11 -8 14 -14 7 -13 -6
2 -18 -8 -25 -21 -6 -14 -19 -26 -27 -28 -11 -3 -11 -1 3 10 9 8 17 21 17 30
0 11 -5 14 -17 10 -16 -6 -16 -5 1 12 15 16 16 20 4 29 -19 15 -42 72 -33 85
3 6 17 11 30 11 13 0 26 6 29 13 3 6 3 1 0 -13 -7 -25 -7 -25 15 -6 20 18 30
43 13 32 -5 -3 -5 20 0 52 12 78 19 89 13 20 -5 -61 9 -82 18 -25 6 41 -3 107
-14 107 -5 0 -9 6 -9 14 0 8 -17 28 -37 45 -33 27 -40 29 -49 16 -14 -19 -24
-19 -24 0 0 8 6 16 13 16 6 1 -8 5 -33 10 -67 12 -480 11 -480 -1z m470 -30
c18 -16 30 -33 26 -37 -4 -5 -21 7 -37 25 -21 24 -37 32 -62 32 -18 0 -37 -4
-42 -9 -6 -6 -32 -12 -60 -14 l-50 -3 58 -2 c77 -3 121 -24 142 -69 20 -41 15
-112 -10 -149 -18 -27 -19 -34 -5 -34 5 0 17 17 26 38 10 22 18 32 21 23 3 -7
2 -16 -3 -20 -5 -3 -10 -13 -12 -21 -2 -8 -10 -22 -18 -31 -8 -9 -11 -19 -7
-22 3 -4 -1 -7 -11 -7 -9 0 -15 4 -12 9 8 13 -56 -11 -73 -27 -9 -9 -31 -12
-58 -10 -23 2 -43 0 -43 -4 0 -5 31 -8 68 -8 70 0 178 -30 164 -45 -4 -4 -1
-5 6 -1 22 13 13 36 -15 37 l-28 1 30 7 c20 4 26 9 17 14 -7 5 -10 13 -7 18 4
5 1 9 -6 9 -10 0 -10 3 -1 12 7 7 12 22 12 35 0 13 5 23 10 23 6 0 8 11 6 25
-3 14 0 25 5 25 6 0 8 4 5 8 -2 4 4 8 15 8 23 0 26 -34 4 -42 -8 -4 -13 -10
-10 -14 3 -5 -4 -25 -15 -44 l-20 -36 24 -21 24 -21 -20 -34 c-11 -19 -16 -34
-10 -34 5 0 17 -16 27 -36 16 -35 17 -35 31 -15 18 26 34 27 34 4 0 -14 -2
-15 -9 -4 -11 17 -25 -3 -17 -25 3 -9 1 -12 -4 -9 -6 3 -10 -3 -10 -15 0 -14
4 -19 12 -14 8 5 9 2 3 -12 -14 -34 -25 -103 -19 -121 5 -14 4 -15 -4 -4 -8
11 -12 9 -23 -10 -7 -13 -13 -27 -14 -31 0 -5 -22 -20 -50 -34 -36 -20 -70
-28 -132 -33 -90 -7 -102 -3 -104 38 -1 20 -3 18 -8 -9 -5 -31 -10 -35 -38
-38 -32 -3 -33 -3 -23 24 6 15 9 33 6 41 -3 7 -1 13 4 13 6 0 10 -5 10 -12 0
-20 12 0 21 34 7 26 7 22 4 -22 -3 -41 -2 -47 4 -25 5 17 9 105 10 196 1 134
4 172 18 200 9 18 18 28 21 21 4 -14 82 -17 82 -3 0 5 -10 11 -22 14 -13 2 -5
4 17 3 24 0 51 7 65 18 l25 18 -25 -8 c-65 -19 -165 -31 -172 -20 -6 8 -11 6
-18 -4 -8 -12 -10 -11 -10 8 0 12 4 22 9 22 4 0 10 46 13 103 6 133 19 161 76
165 23 2 42 7 42 13 0 5 24 9 53 9 45 0 59 -5 87 -30z m-391 8 c11 18 43 12
48 -8 4 -12 3 -13 -5 -1 -6 8 -17 11 -27 7 -14 -5 -16 -14 -12 -44 3 -20 2
-53 -2 -72 -7 -32 -8 -30 -13 30 -3 36 -8 74 -12 85 -6 16 -6 17 3 5 9 -12 13
-13 20 -2z m111 0 c0 6 4 12 9 12 6 0 6 -12 0 -30 -6 -16 -9 -35 -6 -42 4 -9
6 -7 6 3 1 9 8 30 17 45 15 27 15 27 9 4 -18 -67 -24 -111 -25 -170 0 -37 -4
-70 -9 -75 -5 -6 -11 -19 -14 -30 -4 -15 -5 -13 -6 8 0 15 4 27 10 27 13 0 7
207 -6 245 -6 16 -5 17 4 5 7 -10 11 -11 11 -2z m197 -4 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m59 -28 c8 -8 14 -20 14 -26 0 -7 6 -10 14
-7 10 4 12 0 9 -14 -6 -23 -23 -26 -23 -4 0 9 -11 27 -25 40 -13 14 -19 25
-14 25 6 0 17 -6 25 -14z m130 -9 l27 -24 -49 0 c-31 0 -37 2 -16 4 31 4 46
23 18 23 -8 0 -18 5 -21 10 -11 18 14 10 41 -13z m11 -69 c-3 -7 -5 -2 -5 12
0 14 2 19 5 13 2 -7 2 -19 0 -25z m-472 2 c8 -13 -4 -50 -16 -50 -5 0 -9 14
-9 30 0 30 13 40 25 20z m62 -32 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2
-19 0 -25z m350 1 c-1 -12 -5 -15 -11 -9 -6 6 -18 10 -28 10 -23 0 -23 17 0
22 27 6 41 -2 39 -23z m-42 -29 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
10 4 10 3 0 8 -4 11 -10z m93 -35 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10
-15 7 -18z m-398 -116 c0 -5 -5 -7 -10 -4 -6 3 -17 -1 -26 -9 -12 -13 -18 -13
-29 -3 -11 10 -10 13 4 19 25 10 61 9 61 -3z m30 -14 c-8 -9 -12 -61 -13 -157
l0 -143 -6 130 c-3 72 -10 136 -15 144 -6 9 -5 12 1 8 6 -4 13 2 16 13 3 11
11 20 17 20 10 0 10 -3 0 -15z m217 0 c0 -10 -6 -14 -14 -10 -8 3 -22 5 -31 6
-10 1 -7 4 8 9 36 12 37 11 37 -5z m213 -72 c-1 -19 -16 -31 -29 -24 -7 5 -10
14 -7 20 8 12 36 15 36 4z m-73 -215 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7
2 -19 0 -25z m-451 -30 c1 -9 -4 -22 -12 -29 -17 -14 -18 -41 -3 -56 8 -8 7
-13 -2 -18 -8 -5 -10 -4 -5 3 4 7 2 12 -3 12 -7 0 -11 23 -11 56 0 46 3 55 17
52 9 -2 17 -11 19 -20z m61 -15 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2
-21 0 -30z m493 24 c0 -7 -9 -13 -20 -14 -16 -1 -17 1 -7 12 15 18 27 19 27 2z
m40 4 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z
m-70 -41 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9
-10z m-100 -20 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4
4 -10z m40 -25 c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10
-19 10 -25z m-533 -7 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m26 -23 c5 -28 4 -34 -3 -20 -8 19 -14 73 -6 64 2 -2 6 -22 9 -44z m592 25
c-10 -11 -25 -20 -33 -20 -9 0 -6 7 9 20 31 25 47 26 24 0z m-182 -40 c-5 -20
-15 -25 -26 -13 -8 7 11 33 23 33 5 0 6 -9 3 -20z m-48 -20 c18 -8 18 -9 -5
-10 -24 -2 -23 -3 5 -10 23 -6 25 -8 8 -9 -31 -2 -75 17 -68 29 7 12 32 12 60
0z"/>
<path d="M6815 5850 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M5150 6069 c12 -8 8 -9 -15 -8 -16 1 -41 1 -55 0 l-25 -2 25 -11 c14
-6 40 -10 58 -9 27 1 32 -2 32 -21 0 -21 0 -21 -14 -2 -8 10 -17 16 -20 13 -7
-6 16 -59 25 -59 4 0 3 -18 -1 -41 -6 -33 -5 -39 7 -35 8 3 21 6 28 6 7 0 12
8 11 18 -1 13 5 16 26 14 15 -2 37 3 48 11 20 14 20 15 3 21 -17 7 -17 8 1 22
11 8 25 14 30 14 6 -1 0 -7 -14 -15 l-25 -14 27 -1 c20 0 26 -4 22 -15 -4 -8
-15 -15 -25 -15 -10 0 -19 -5 -19 -10 0 -11 48 -3 66 11 6 5 14 6 17 2 4 -3 7
-1 7 5 0 7 -7 12 -15 12 -13 0 -16 5 -14 26 0 10 -49 44 -64 44 -9 0 -18 4
-21 9 -3 5 -20 14 -36 20 -35 12 -32 -5 4 -19 20 -8 19 -9 -6 -9 -27 -1 -55
29 -41 43 3 3 -5 6 -18 6 -19 0 -21 -2 -9 -11z m-30 -19 c0 -5 -2 -10 -4 -10
-3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m123 -39 c5 -1 5 -5 2
-11 -3 -5 -1 -10 4 -10 17 0 13 -17 -5 -24 -9 -4 -22 -2 -29 4 -10 8 -15 6
-18 -7 -4 -14 -5 -13 -6 5 -1 14 5 22 17 23 16 1 16 2 0 6 -26 7 -22 20 5 17
12 -1 26 -3 30 -3z m-53 -91 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z"/>
<path d="M3373 6035 c-9 -24 1 -27 20 -5 14 17 15 20 2 20 -9 0 -18 -7 -22
-15z"/>
<path d="M3417 6023 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M5512 5980 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6845 5779 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25 -2z"/>
<path d="M5310 5611 c0 -5 7 -11 15 -15 8 -3 15 -15 15 -26 0 -26 27 -26 35 0
8 25 -1 41 -19 34 -8 -3 -17 -1 -21 5 -8 13 -25 14 -25 2z"/>
<path d="M5135 5570 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M3465 5490 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M4792 5481 c-41 -11 -61 -20 -57 -28 2 -7 0 -13 -4 -13 -5 0 -11 4
-13 10 -2 6 -14 6 -33 1 -21 -7 -24 -10 -10 -10 16 -1 17 -3 6 -10 -27 -17 53
-124 96 -128 28 -2 31 20 5 39 -23 16 -37 49 -18 42 23 -9 87 -94 84 -111 -2
-14 7 -20 47 -26 85 -15 257 -19 268 -8 8 8 -24 12 -108 13 -66 1 -101 3 -77
5 47 5 58 23 13 23 -15 0 -40 9 -54 20 -14 11 -30 20 -36 20 -16 0 -13 -20 4
-26 21 -9 19 -24 -4 -24 -10 0 -21 8 -25 18 -3 9 -8 22 -11 28 -3 7 2 19 11
28 15 16 15 19 -6 41 -20 21 -21 28 -12 69 5 25 7 45 3 44 -3 0 -34 -8 -69
-17z m6 -59 c6 -18 -15 -14 -22 4 -4 10 -1 14 6 12 6 -2 14 -10 16 -16z m39
-50 c-14 -17 -22 0 -12 26 5 14 7 14 15 2 5 -9 4 -20 -3 -28z"/>
<path d="M3400 5455 c-19 -13 -40 -28 -46 -32 -18 -14 -49 -33 -64 -40 -26
-12 -244 -163 -420 -290 -74 -54 -206 -155 -286 -218 -50 -40 -101 -79 -112
-86 -12 -8 -22 -18 -22 -23 0 -6 5 -5 12 2 20 20 28 14 49 -35 119 -278 292
-502 509 -661 47 -34 90 -65 96 -69 51 -38 241 -128 339 -161 144 -49 275 -73
437 -79 230 -9 436 27 648 114 83 34 226 106 240 122 3 4 25 19 50 36 25 16
56 39 70 51 105 89 212 195 281 280 52 62 113 153 107 160 -3 3 -16 -11 -28
-30 -28 -47 -32 -45 -15 5 8 23 15 56 14 73 0 23 -4 16 -15 -29 -8 -33 -16
-70 -18 -83 -1 -12 -7 -22 -12 -23 -5 0 -15 -12 -23 -27 -69 -134 -290 -317
-511 -424 -265 -129 -651 -173 -940 -106 -226 52 -453 160 -615 291 -207 169
-348 364 -458 632 -34 83 -36 78 58 137 47 29 105 65 128 80 23 15 69 43 102
63 33 20 87 54 120 76 33 21 67 42 75 46 8 4 58 32 110 63 52 31 128 76 168
99 41 24 71 45 69 48 -3 2 -14 0 -24 -5 -10 -6 -34 -7 -53 -3 -35 7 -35 7 -7
13 15 3 27 1 27 -3 0 -5 9 -4 19 3 11 7 17 16 15 20 -3 5 -11 5 -17 1 -6 -4
-21 -8 -34 -9 -22 -3 -22 -2 5 21 35 31 17 31 -28 0z m40 -64 c0 -9 -52 -22
-59 -15 -4 3 37 22 52 23 4 1 7 -3 7 -8z"/>
<path d="M3560 5460 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M3510 5430 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6015 5329 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25 -2z"/>
<path d="M5667 5313 c-4 -3 -2 -13 5 -20 9 -11 9 -17 -3 -24 -8 -5 -9 -9 -4
-9 23 0 31 15 20 37 -7 13 -15 20 -18 16z"/>
<path d="M5153 5296 c-4 -7 -35 -18 -68 -23 l-60 -10 83 1 c59 1 85 -3 91 -12
7 -11 11 -11 24 2 8 9 20 16 26 16 6 0 11 7 11 15 0 8 -6 15 -14 15 -17 0 -38
-24 -29 -33 3 -4 1 -7 -5 -7 -7 0 -12 5 -12 11 0 11 -24 39 -34 39 -3 0 -9 -6
-13 -14z"/>
<path d="M4777 5283 c3 -8 12 -13 20 -11 19 4 12 22 -9 23 -10 1 -14 -4 -11
-12z"/>
<path d="M6010 5260 c-24 -15 -7 -18 20 -3 14 7 18 13 10 13 -8 0 -22 -4 -30
-10z"/>
<path d="M3780 5049 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2263 4988 c-82 -69 -123 -108 -283 -268 -178 -177 -278 -292 -358
-411 -164 -244 -182 -408 -52 -472 36 -17 67 -22 158 -25 155 -6 267 13 527
86 110 31 514 190 425 167 -175 -44 -473 -85 -617 -85 -102 0 -210 24 -240 52
-78 73 27 265 297 542 69 71 124 133 122 138 -1 5 2 7 8 3 5 -3 31 15 57 40
27 25 41 43 31 40 -13 -5 -16 0 -15 34 1 23 -1 41 -5 41 -3 0 -4 18 -1 40 6
41 2 44 -44 43 -15 -1 -9 13 20 45 29 32 15 27 -30 -10z"/>
<path d="M3698 4963 c-63 -65 -98 -103 -98 -107 0 -2 -25 -40 -56 -86 -60 -90
-114 -191 -114 -214 0 -10 19 -16 68 -20 37 -4 108 -11 157 -16 50 -5 182 -19
295 -30 113 -11 219 -22 236 -24 35 -5 37 -4 83 47 l35 37 -32 -4 c-18 -2 -32
1 -32 6 0 5 -8 8 -17 7 -41 -4 -63 2 -63 16 0 11 -11 15 -40 15 -36 0 -40 3
-40 25 0 18 -5 25 -20 25 -11 0 -20 5 -20 10 0 6 -8 10 -18 10 -10 0 -29 11
-42 25 -13 14 -20 25 -15 25 5 0 -2 11 -15 25 -12 13 -26 22 -30 20 -3 -2 -21
9 -38 24 -17 16 -44 38 -61 50 -16 12 -33 30 -36 41 -3 10 -15 22 -26 25 -10
4 -19 11 -19 16 0 6 -7 17 -15 26 -14 13 -13 18 6 44 29 41 20 36 -33 -18z
m572 -429 c0 -3 -7 -12 -15 -20 -16 -17 -18 -14 -9 10 6 15 24 23 24 10z"/>
<path d="M2302 4988 c-14 -14 -16 -28 -3 -28 9 0 24 30 18 36 -2 2 -9 -2 -15
-8z"/>
<path d="M2342 4840 c-18 -29 -15 -36 8 -15 20 18 26 35 12 35 -4 0 -13 -9
-20 -20z"/>
<path d="M2381 4839 c-13 -11 -21 -22 -18 -25 3 -3 16 3 28 15 29 27 21 35
-10 10z"/>
<path d="M4340 4605 c-13 -14 -21 -27 -18 -30 2 -3 17 8 31 25 33 36 20 41
-13 5z"/>
<path d="M5321 4594 c-12 -19 -16 -32 -11 -29 11 7 43 65 36 65 -2 0 -13 -16
-25 -36z"/>
<path d="M2753 3572 c-35 -19 -143 -157 -143 -183 0 -26 20 -29 175 -29 114 0
145 3 155 15 11 13 -3 15 -119 15 -74 0 -131 4 -131 9 0 5 6 16 13 23 7 7 28
36 46 65 19 29 41 55 50 58 9 4 68 6 132 6 103 -2 118 0 139 19 l23 20 -154 0
c-126 -1 -160 -4 -186 -18z"/>
<path d="M4410 3559 c-8 -12 -22 -31 -31 -43 -8 -11 -37 -51 -63 -88 l-48 -68
36 0 c33 0 40 6 79 59 l42 60 68 0 c59 1 83 -8 59 -23 -4 -2 -22 -25 -40 -50
l-33 -46 39 0 c37 0 42 4 81 61 23 34 40 67 37 74 -5 15 -53 25 -122 25 -58 0
-62 3 -39 26 23 24 18 34 -18 34 -22 0 -37 -7 -47 -21z"/>
<path d="M5374 3558 c-37 -41 -124 -165 -124 -176 0 -17 29 -22 142 -22 l111
0 36 38 c48 50 68 89 54 103 -6 6 -47 13 -92 16 -44 3 -81 8 -81 10 0 3 7 11
15 19 23 23 18 34 -13 34 -17 0 -37 -9 -48 -22z m146 -84 c0 -3 -14 -24 -31
-45 -29 -37 -33 -39 -88 -39 -32 0 -61 3 -64 6 -4 4 7 24 24 45 30 38 32 39
95 39 35 0 64 -3 64 -6z"/>
<path d="M3105 3513 c-23 -4 -100 -105 -100 -131 0 -15 16 -17 136 -20 115 -2
138 0 143 13 5 13 -10 15 -100 15 -80 0 -105 3 -101 13 2 8 33 13 98 14 52 1
102 2 111 2 18 1 68 66 68 88 0 10 -29 13 -117 12 -65 -1 -127 -4 -138 -6z
m165 -43 c0 -6 -32 -10 -76 -10 -47 0 -73 4 -69 10 3 6 37 10 76 10 39 0 69
-4 69 -10z"/>
<path d="M3430 3513 c-19 -8 -27 -17 -68 -76 -52 -74 -45 -78 109 -75 95 2
134 7 137 16 3 9 -21 12 -97 12 -56 0 -101 4 -101 9 0 16 30 21 131 21 l99 0
26 39 c14 22 23 45 19 50 -7 12 -228 14 -255 4z m170 -43 c0 -6 -32 -10 -76
-10 -47 0 -73 4 -69 10 3 6 37 10 76 10 39 0 69 -4 69 -10z"/>
<path d="M3747 3513 c-23 -6 -100 -120 -93 -138 4 -11 30 -15 96 -17 l92 -3
-21 -27 c-27 -34 -27 -38 8 -38 22 0 37 10 63 43 68 84 107 147 102 161 -3 7
-22 16 -42 20 -42 7 -178 6 -205 -1z m171 -40 c-2 -5 -14 -25 -27 -45 l-23
-38 -70 0 c-38 0 -68 3 -66 8 2 4 14 24 28 44 25 37 26 38 93 38 38 0 67 -3
65 -7z"/>
<path d="M4070 3514 c-16 -5 -100 -115 -100 -132 0 -17 29 -22 142 -22 l111 0
37 40 c51 55 67 87 51 103 -12 12 -210 21 -241 11z m170 -40 c0 -3 -14 -23
-32 -45 -31 -38 -33 -39 -96 -39 -35 0 -62 3 -60 8 2 4 14 24 27 45 23 37 23
37 92 37 38 0 69 -3 69 -6z"/>
<path d="M4710 3513 c-19 -5 -72 -78 -63 -87 3 -3 49 -6 101 -6 83 0 93 -2 82
-15 -10 -12 -36 -15 -115 -15 -79 0 -105 -3 -115 -15 -11 -13 4 -15 125 -15
l137 0 30 30 c50 51 37 65 -65 68 -126 4 -99 22 34 22 60 0 109 2 109 4 0 2 3
11 6 20 5 14 -9 16 -122 15 -71 -1 -136 -3 -144 -6z"/>
<path d="M4977 3456 c-26 -35 -47 -68 -47 -74 0 -17 29 -22 135 -22 94 0 155
12 155 30 0 3 18 31 40 60 22 29 40 57 40 62 0 4 -14 8 -30 8 -31 0 -35 -4
-92 -80 -32 -42 -37 -45 -87 -48 -84 -5 -88 0 -41 61 22 30 40 57 40 60 0 4
-15 7 -33 7 -29 0 -39 -8 -80 -64z"/>

</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
